<template>
  <div class="">
    <center-title title="售后率"
      ><el-button
        type="primary"
        class="whtie submit"
        @click="exportConsumeRecord"
        >导出</el-button
      ></center-title
    >
    <el-form :inline="true" :model="form" class="demo-form-inline">
      <div class="row-between"></div>
    </el-form>
    <el-table :data="Aftersalesrate">
      <el-table-column align="center" prop="yy" label="售后类型 " width="230px">
              </el-table-column
      >
            <el-table-column
        align="center"
        prop="order_count"
        label="订单总数"
        width="230px"
      >
              </el-table-column
      >
            <el-table-column
        align="center"
        prop="tjsl"
        label="售后订单数量"
        width="230px"
      >
              </el-table-column
      >
            <el-table-column
        align="center"
        prop="shl"
        label="售后订单率"
        width="230px"
      >
              </el-table-column
      >
    </el-table>
    <div class="row-center pagination mt30">
      <make-pagination
        @resetList="resetList"
        :orderObject="orderObject"
      ></make-pagination>
    </div>
  </div>
</template>
<script>
import { download } from "@/utils/plugin";
import CenterTitle from "../components/CenterTitle";
export default {
  name: "",
  props: {},
  components: {
    "center-title": CenterTitle,
  },
  data() {
    return {
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一年",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 1080);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },

      page_no: 1,
      page_size: 10,
      Aftersalesrate: [],
      Wuliaofenlei: [],
      Caigoupeople: [],
      result2: [],
      dialogVisible: false,
      uploadDialogVisible: false,
      download: process.env.VUE_APP_DOWNLOAD_URL,
      form: {
        wuliaofl: "",
        dept: "",
        caigouren: "",
        date: [],
        start_time: "",
        end_time: "",
      },
      orderObject: {},
      auditBox: {
        id: "",
        type: 1,
        reject_reason: "",
      },
      uploadPay: "",
    };
  },
  computed: {},
  watch: {},
  methods: {
    exportConsumeRecord() {
      this.$api;
      download(
        "/Aftersales/goodsExcel",
        {
          type: this.queryType,
          page_no: this.page_no,
          page_size: this.page_size,
          ...this.form,
          start_time: this.form.date && this.form?.date[0],
          end_time: this.form.date && this.form?.date[1],
        },
        `售后率${new Date().getTime()}.xlsx`
      );
    },
    onSubmit() {},
    resetList(e) {
      this.page_no = e;
      this.getAftersalesrate();
    },
    /**
     * @description 获取对账列表
     * @returns void
     */
    getAftersalesrate() {
      this.$api
        .getAftersalesrateApi({
          type: this.queryType,
          page_no: this.page_no,
          page_size: this.page_size,
          ...this.form,
          start_time: this.form.date && this.form?.date[0],
          end_time: this.form.date && this.form?.date[1],
        })
        .then((res) => {
          this.Aftersalesrate = res.data.list;
          this.Caigoupeople = res.data.caigouresult;
          this.Wuliaofenlei = res.data.wuliaoresult;
          this.result2 = res.data.result2;
          //result2
          // console.log(this.Caigoupeople);
          let { lists, count, page_size: size, page_no: page } = res.data;
          this.orderObject = res.data;
          this.orderObject = {
            page,
            size,
            count,
            lists,
            // caigouresult,
          };
        });
    },
    setRmOperate() {
      this.$api.setRmOperateApi(this.auditBox).then((res) => {
        if (res.code == 1) {
          this.resetList(1);
          this.dialogVisible = false;
        }
      });
    },
    resetList(e) {
      this.page_no = e;
      this.getAftersalesrate();
    },
  },
  created() {
    this.getAftersalesrate();
  },
  mounted() {},
  beforeDestroy() {},
};
</script>
<style lang="scss" scoped>
/deep/ .el-form {
  .el-form-item__content,
  .el-input__inner {
    // height: 32px;
    // line-height: 32px;
    // width: 240px;
    border-radius: 0;
  }

  .el-input__icon {
    line-height: 32px;
  }

  .el-date-editor .el-range-separator {
    width: auto;
  }
}

.el-upload {
  // border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;

  button {
    border-radius: 5px;
    padding: 10px 20px;
  }
}

.el-upload:hover {
  border-color: inherit;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

/deep/ .el-table {
  border: $-solid-border;
  border-bottom: none;

  th {
    background-color: $-color-center;
    font-size: 16px;
    font-weight: normal;
    color: $-color-lighter;
  }

  td {
    &:nth-child(2) {
      .cell {
        padding: 0 10px;
      }
    }

    .cell {
      padding: 0px 20px;
      line-height: 1.3;
      word-break: break-all;
    }
  }

  th,
  td {
    border-right: $-solid-border;

    &:last-child {
      border-right: none;
    }
  }
}
</style>
